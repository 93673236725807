<template>
  <div class="">
    <div class="Paramlist:box-title">Statistics - {{ now }}</div>

    <v-card>
      <v-data-table
        dense
        :headers="outerHeaders"
        :items="params"
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        :items-per-page="20"
        item-key="id"
        show-expand
        class="elevation-1"
        hide-default-footer
      >
        <template v-slot:item="{ item }">
          <tr height="80">
            <td></td>
            <td class="text-center text-h5" key="">{{ item.home_value }}</td>
            <td>
              <v-progress-linear
                v-model="item.homeRatio"
                :buffer-value="bufferValue"
                color="red"
                background-color="var(--player-border)"
                reverse
                height="10"
              ></v-progress-linear>
            </td>
            <td class="text-center text-h5">{{ item.parameter }}</td>
            <td>
              <v-progress-linear
                v-model="item.awayRatio"
                :buffer-value="bufferValue"
                color="green"
                background-color="var(--player-border)"
                height="10"
              ></v-progress-linear>
            </td>
            <td class="text-center text-h5">{{ item.away_value }}</td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<style scoped>
.v-progress-linear {
  height: 50px;
}
</style>
<script>
import axios from "axios";
export default {
  name: "ParamsDatatable",
  data() {
    return {
      value: 2,
      bufferValue: 200,
      expanded: [],
      singleExpand: false,
      params: [],
      loading: true,
      options: {},
      dialogZip: false,
      editedIndex: -1,
      editedItem: {},
      editedSubIndex: -1,
      editedSubItem: {},
      outerHeaders: [
        {
          text: "Home Team",
          value: "home_value",
          align: "center",
          sortable: false,
        },
        {
          text: "Progress",
          align: "center",
          sortable: false,
        },
        { text: "Parameter", align: "center", value: "parameter" },
        {
          text: "Progress",
          align: "center",
          sortable: false,
        },
        {
          text: "Away Team",
          value: "home_value",
          align: "center",
          sortable: false,
        },
      ],
      refreshTimer: "",
      now: "",
    };
  },
  watch: {
    options: {
      handler() {
        this.readDataFromAPI();
      },
      dialogZip(val) {
        val || this.closeZip();
      },
    },
    deep: true,
  },
  computed: {
    idmatch() {
      return this.$store.state.idmatch;
    },
    headerConfig() {
      return this.$store.getters.getHeaderConfig;
    },
    homeTeamName() {
      return this.$store.state.field.hometeamname;
    },
    awayTeamName() {
      return this.$store.state.field.awayteamname;
    },
  },
  methods: {
    async readDataFromAPI() {
      let newparams = [
        {
          parameter: "Shots On Target",
          home_value: 0,
          away_value: 0,
          tot_value: 0,
        },
        {
          parameter: "Total Shots",
          home_value: 0,
          away_value: 0,
          tot_value: 0,
        },
        { parameter: "Woodworks", home_value: 0, away_value: 0, tot_value: 0 },
        { parameter: "Offsides", home_value: 0, away_value: 0, tot_value: 0 },
        {
          parameter: "Yellow Cards",
          home_value: 0,
          away_value: 0,
          tot_value: 0,
        },
        { parameter: "Fouls", home_value: 0, away_value: 0, tot_value: 0 },
        { parameter: "Red Cards", home_value: 0, away_value: 0, tot_value: 0 },
        { parameter: "Corners", home_value: 0, away_value: 0, tot_value: 0 },
      ];

      this.now = new Date().toLocaleString();
      this.loading = true;
      const { page, itemsPerPage } = this.options;
      //console.log(process.env.VUE_APP_API_URL + "/stats/" + this.idmatch);

      // Recupera la lista di tutti i container per i match attivi
      axios
        .get(process.env.VUE_APP_API_URL + "/stats/" + this.idmatch)
        .then((response) => {
          this.loading = false;
          // Legge in ordine di match e crea la struttura per master-details
          let paramsData = response.data;
          for (const item of paramsData) {
            let objIndex1 = -1;
            let objIndex2 = -1;
            switch (item.typeId) {
              case 4: // Fouls
                if (item.outcome == "0") {
                  objIndex1 = newparams.findIndex(
                    (obj) => obj.parameter == "Fouls"
                  );
                }
                break;

              case 2: // Offsides
                objIndex1 = newparams.findIndex(
                  (obj) => obj.parameter == "Offsides"
                );
                break;

              case 15: // Shots On Target + Total Shots
                objIndex1 = newparams.findIndex(
                  (obj) => obj.parameter == "Shots On Target"
                );
                objIndex2 = newparams.findIndex(
                  (obj) => obj.parameter == "Total Shots"
                );
                break;

              case 16: // Total Shots
                objIndex1 = newparams.findIndex(
                  (obj) => obj.parameter == "Total Shots"
                );
                break;

              case 13: // Total Shots
                objIndex1 = newparams.findIndex(
                  (obj) => obj.parameter == "Total Shots"
                );
                break;

              case 14: // Woodworks
                objIndex1 = newparams.findIndex(
                  (obj) => obj.parameter == "Woodworks"
                );
                break;

              case 17: // Woodworks
                objIndex1 = newparams.findIndex(
                  (obj) => obj.parameter == "Yellow Cards"
                );
                break;

              case 6: // Fouls
                if (item.outcome == "1") {
                  objIndex1 = newparams.findIndex(
                    (obj) => obj.parameter == "Corners"
                  );
                }
                break;
            }

            if (objIndex1 >= 0) {
              if (item.home_away == "H") {
                newparams[objIndex1].home_value += item.counter;
              } else {
                newparams[objIndex1].away_value += item.counter;
              }
              newparams[objIndex1].tot_value += item.counter;
            }

            if (objIndex2 >= 0) {
              if (item.home_away == "H") {
                newparams[objIndex2].home_value += item.counter;
              } else {
                newparams[objIndex2].away_value += item.counter;
              }
              newparams[objIndex2].tot_value += item.counter;
            }
          }
          for (let i = 0; i < newparams.length; i++) {
            newparams[i].homeRatio =
              newparams[i].tot_value == 0
                ? 0
                : (newparams[i].home_value * 100) / newparams[i].tot_value;
            newparams[i].awayRatio =
              newparams[i].tot_value == 0
                ? 0
                : (newparams[i].away_value * 100) / newparams[i].tot_value;
          }
          this.params = newparams;
          let a = 1;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleClick(row) {
      //console.log(row.postId);
      //this.$emit("setPost", row.postId, row.hometeam, row.awayteam);
    },
    reloadPage() {
      window.location.reload();
    },
    startDownload(url) {
      window.open(url, "_blank").focus();
    },
  },
  mounted() {},
  created() {
    this.readDataFromAPI();
    this.refreshTimer = setInterval(this.readDataFromAPI, 5000);
  },
  destroyed() {
    clearInterval(this.refreshTimer);
  },
};
</script>
