var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("div", { staticClass: "Paramlist:box-title" }, [
        _vm._v("Statistics - " + _vm._s(_vm.now))
      ]),
      _c(
        "v-card",
        [
          _c("v-data-table", {
            staticClass: "elevation-1",
            attrs: {
              dense: "",
              headers: _vm.outerHeaders,
              items: _vm.params,
              "single-expand": _vm.singleExpand,
              expanded: _vm.expanded,
              "items-per-page": 20,
              "item-key": "id",
              "show-expand": "",
              "hide-default-footer": ""
            },
            on: {
              "update:expanded": function($event) {
                _vm.expanded = $event
              }
            },
            scopedSlots: _vm._u([
              {
                key: "item",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("tr", { attrs: { height: "80" } }, [
                      _c("td"),
                      _c(
                        "td",
                        { key: "", staticClass: "text-center text-h5" },
                        [_vm._v(_vm._s(item.home_value))]
                      ),
                      _c(
                        "td",
                        [
                          _c("v-progress-linear", {
                            attrs: {
                              "buffer-value": _vm.bufferValue,
                              color: "red",
                              "background-color": "var(--player-border)",
                              reverse: "",
                              height: "10"
                            },
                            model: {
                              value: item.homeRatio,
                              callback: function($$v) {
                                _vm.$set(item, "homeRatio", $$v)
                              },
                              expression: "item.homeRatio"
                            }
                          })
                        ],
                        1
                      ),
                      _c("td", { staticClass: "text-center text-h5" }, [
                        _vm._v(_vm._s(item.parameter))
                      ]),
                      _c(
                        "td",
                        [
                          _c("v-progress-linear", {
                            attrs: {
                              "buffer-value": _vm.bufferValue,
                              color: "green",
                              "background-color": "var(--player-border)",
                              height: "10"
                            },
                            model: {
                              value: item.awayRatio,
                              callback: function($$v) {
                                _vm.$set(item, "awayRatio", $$v)
                              },
                              expression: "item.awayRatio"
                            }
                          })
                        ],
                        1
                      ),
                      _c("td", { staticClass: "text-center text-h5" }, [
                        _vm._v(_vm._s(item.away_value))
                      ])
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }